import { createSignal } from "solid-js";
import { InputViewProps, FormControlData, FormControlValue } from "../model";
import { createFormControl } from "solid-forms";

export function Upload<V extends FormControlValue, D extends FormControlData>({
  control,
  ...props
}: InputViewProps<V, D>) {
  let ref;
  const [title, setTitle] = createSignal("Drag files here or");
  const [dragging, setDragging] = createSignal(false);
  const _control = Array.isArray(control) ? createFormControl<V, D>(...control) : control;
  const handleFileDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      _control.setValue(file as V);
      setTitle(file?.name);
      setDragging(true);
    }
  };
  const handleFileDrag = (e) => {
    e.preventDefault();
    if (e.type === "dragenter" || e.type === "dragover") {
      setTitle("Drop the file here ");
      setDragging(true);
    } else if (e.type === "dragleave") {
      setTitle("Drag files here or ");
      setDragging(false);
    }
  };
  const onDragLeave = () => {
    setTitle("Drag files here or ");
    setDragging(false);
  };
  const onClickUpload = (e) => {
    ref?.click();
  };
  const handleManualUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      console.log(file);
      _control.setValue(file);
      setTitle(file?.name);
      setDragging(true);
    }
  };

  return (
    <div
      onDragOver={handleFileDrag}
      onDragLeave={onDragLeave}
      onDrop={handleFileDrop}
      class="flex flex-col justify-center items-center bg-inherit border-2 border#p border-dashed text-14px px-2.5 py-20px w-630px relative"
    >
      {/* TODO: add props to input */}
      <input
        id="upload-file-input"
        type="file"
        class="hidden"
        ref={ref}
        classList={{
          invalid: !!_control.errors,
          touched: _control.isTouched,
          disabled: _control.isDisabled,
          // required: control.isRequired,
        }}
        onChange={handleManualUpload}
        onblur={() => _control.markTouched(true)}
        onfocus={() => _control.markTouched(true)}
        onfocusin={() => _control.markTouched(true)}
        onfocusout={() => _control.markTouched(false)}
        disabled={_control.isDisabled}
        required={_control.isRequired}
        accept={props.accept ?? "image/jpeg,image/gif,image/png,application/pdf,image/x-eps"}
      />

      <div class="flex flex-col justify-center items-center gap-5px">
        <i class="icon-local:upload-icon w-29.5px h-19.6px" />
        <p>
          {title()}
          {!dragging() && (
            <span class="underline ms-2px cursor-pointer" onClick={onClickUpload}>
              choose a file
            </span>
          )}
        </p>
      </div>
    </div>
  );
}
